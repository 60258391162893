import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { createApolloClient } from '@lib/apollo'
import {
	GetMyPermissionsQuery,
	GetMyPermissionsDocument,
	Permissions
} from '../generated/graphql'

export function hasPermission(
	permission: Permissions | string | number,
	authorization: Permissions[]
): boolean {
	// Field in Storyblok is a single select so it returns as type number | string
	// We know that it returns a Permissions value
	return authorization?.includes(permission as Permissions) === true
}

const getMyPermissions = async (
	apolloClient: ApolloClient<NormalizedCacheObject>,
	accessToken: string
) => {
	const accessRight = await apolloClient.query<GetMyPermissionsQuery>({
		query: GetMyPermissionsDocument,
		context: {
			headers: {
				authorization: `Bearer ${accessToken}`
			}
		}
	})

	return accessRight?.data?.myPermissions ?? []
}

export const getPermissions = async (
	accessToken: string
): Promise<Permissions[]> => {
	const apolloClient = createApolloClient()
	return getMyPermissions(apolloClient, accessToken)
}
