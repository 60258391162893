import { theme } from '@damen/ui'
import styled from 'styled-components'
import { hexToRgb } from '@utils/helper'
import { Toggled } from './types'

export const Wrapper = styled.div`
	margin-bottom: 24px;

	@media ${theme.legacyMediaQueries.sm} {
		margin-bottom: 48px;
	}
`

export const Details = styled.div<Toggled>`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-bottom: ${({ toggled }) => (toggled ? '48px' : '0')};
	border-bottom: ${({ toggled }) =>
		toggled
			? `1px solid ${hexToRgb(theme.colors.white, {
					alpha: 0.4,
					format: 'css'
			  })}`
			: 'none'};
	max-height: ${({ toggled }) => (toggled ? '1000px' : '0')};
	overflow: hidden;
	transition: 0.2s ease-in-out;

	dl {
		width: 50%;
		padding-right: 16px;

		&:nth-child(3),
		&:nth-child(4) {
			margin-top: 16px;
		}
	}

	@media ${theme.legacyMediaQueries.sm} {
		max-height: none;
		padding-bottom: 48px;
		border-bottom: 1px solid
			${hexToRgb(theme.colors.white, {
				alpha: 0.4,
				format: 'css'
			})};

		dl {
			width: 25%;
			padding-right: 16px;

			&:nth-child(3),
			&:nth-child(4) {
				margin-top: 0;
			}
		}
	}
`

export const MobileToggle = styled.div<Toggled>`
	margin-bottom: 20px;

	button {
		color: ${theme.colors.white};

		svg {
			transform: ${({ toggled }) =>
				toggled
					? 'rotate(-90deg)!important'
					: 'rotate(90deg)!important'};
		}
	}

	@media ${theme.legacyMediaQueries.sm} {
		display: none;
	}
`

export const DataListTitle = styled.dt`
	margin-bottom: 8px;
	font-family: ${theme.fonts.overline};
	font-size: ${theme.typography.fontSizeOverline}px;
	color: ${theme.colors.white};
	letter-spacing: 0.5px;
	text-transform: uppercase;
`

export const DataListDescription = styled.dd`
	font-size: ${theme.typography.fontSizeTextSmall}px;
	color: ${theme.colors.white};
	letter-spacing: 0.2px;
	line-height: 30px;
`
