import { union } from '@utils/text'

export const formatUrl = (linkStory) => {
	if (linkStory.url.length > 0 && linkStory.url.startsWith('home/')) {
		return linkStory.url.replace('home', '')
	}

	if (linkStory.url.length > 0) {
		return union(window.location.pathname, linkStory.url, '/')
	}

	return ''
}
