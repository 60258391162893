import React from 'react'
import { Icon, theme } from '@damen/ui'
import Link from 'next/link'
import { useSession } from 'next-auth/react'
import { hasPermission } from '@utils/authorization'
import { Tile, TileTitle, Wrapper } from './styles'
import { LinkTilesProps } from './types'

const getIcon = (iconName: string) => {
	switch (iconName) {
		case 'service':
			return (
				<Icon.Wrench width={20} height={21} fill={theme.colors.white} />
			)

		case 'document':
			return (
				<Icon.FileDefault
					width={20}
					height={21}
					fill={theme.colors.white}
				/>
			)

		case 'part':
			return (
				<Icon.ShoppingCart
					width={20}
					height={21}
					fill={theme.colors.white}
				/>
			)

		case 'history':
			return (
				<Icon.History
					width={20}
					height={21}
					fill={theme.colors.white}
				/>
			)

		default:
			return null
	}
}

const LinkTiles: React.FC<LinkTilesProps> = ({ blok, currentPageUrl }) => {
	const { blocks } = blok

	const { data: session } = useSession()
	const { permissions } = session ?? {}

	return (
		<Wrapper>
			{blocks &&
				blocks.map((item) => {
					const fullLinkUrl = `${currentPageUrl ?? ''}${
						item.relativeUrl
					}`

					return (
						hasPermission(item.authorization, permissions) && (
							<Tile key={item.title}>
								<Link href={fullLinkUrl}>
									<>
										{getIcon(item.icon)}
										<TileTitle>{item.title}</TileTitle>
									</>
								</Link>
							</Tile>
						)
					)
				})}
		</Wrapper>
	)
}

export default LinkTiles
