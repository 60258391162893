import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
`

export const SecondaryCta = styled.div`
	margin-left: 20px;
`
