import { theme } from '@damen/ui'
import styled from 'styled-components'
import ContainerComponent from '@components/Container/Container'
import { SortIconTextProps } from '@components/UI/SortIcon/types'

export const Container = styled(ContainerComponent)`
	padding-bottom: 40px;
`
export const PaginationWrapper = styled.div<{
	layout?: 'infinite' | 'default'
}>`
	display: flex;
	flex-direction: ${({ layout }) =>
		layout === 'infinite' ? 'column' : 'row'};
	align-items: ${({ layout }) =>
		layout === 'infinite' ? 'center' : 'flex-end'};
	justify-content: ${({ layout }) =>
		layout === 'infinite' ? 'center' : 'flex-end'};
	margin-top: 32px;
`

export const TableWrapper = styled.div`
	margin-top: 40px;
`

// Made position absolute because component library changes could not be made (close proximity to go live)
// Fix is regarding bug 111850 https://dev.azure.com/DamenProjectHub/DXP/_workitems/edit/111950
export const TableIconWrapper = styled.span`
	margin-left: 8px;
	display: inline-block;
	position: absolute;
	right: 0;
	z-index: 1;
	cursor: pointer;
`

// p tag styling needed for AssetComponentsOverviewTable
export const TableItemTitle = styled.span`
	color: ${theme.colors.blue};
	text-decoration: underline;

	p {
		color: ${theme.colors.blue};
	}

	@media ${theme.legacyMediaQueries.md} {
		color: inherit;
		text-decoration: none;

		p {
			color: inherit;
		}
	}
`

export const Title = styled.h2`
	margin-top: 18px;
	margin-bottom: 56px;
	font-family: ${theme.fonts.body};
	font-size: ${theme.typography.fontSizeHeadingFour}px;
	color: ${theme.colors.marineBlack};
`

export const SortIconWrapper = styled.div`
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	padding: 8px 40px 8px 0;
	display: flex;
	align-items: center;
`

export const SortIconText = styled.span<SortIconTextProps>`
	position: relative;
	line-height: 32px;
	visibility: hidden;
	padding-right: ${({ hasIcon }) => (hasIcon ? '24px' : '0')};
`

export const SortIconAsc = styled.span`
	margin: auto 0 auto 16px;

	svg {
		transform: rotate(-90deg);
	}
`

export const SortIconDesc = styled.span`
	margin: auto 0 auto 16px;

	svg {
		transform: rotate(90deg);
	}
`
