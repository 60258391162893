import React, { useEffect, useMemo } from 'react'
import { useHeaderContext } from '@contexts/HeaderContext'
import Head from 'next/head'
import { MetaTagProps, PageHeadProps } from './types'

const SEO_DATA_ARRAY = [
	{
		prefix: 'og',
		key: 'title'
	},
	{
		prefix: 'og',
		key: 'description'
	},
	{
		prefix: 'og',
		key: 'image'
	},
	{
		prefix: 'twitter',
		key: 'title'
	},
	{
		prefix: 'twitter',
		key: 'description'
	},
	{
		prefix: 'twitter',
		key: 'image'
	}
]

const PageHead = ({
	title,
	seoData,
	headerColor = 'white',
	children
}: PageHeadProps) => {
	const { setHeaderColor } = useHeaderContext()

	useEffect(() => {
		setHeaderColor(headerColor)
	}, [headerColor, setHeaderColor])

	// This is only ever executed once.  We useMemo to transform our data
	// and then do a simple map to render out the meta tags
	const metaTags: MetaTagProps[] = useMemo(
		() =>
			SEO_DATA_ARRAY.map((item) => {
				if (
					item.key === 'image' &&
					seoData?.[`${item.prefix}_${item.key}`] === ''
				) {
					return null
				}

				if (item.key === 'title') {
					let metaTitle: string
					if (title) {
						metaTitle = title
					} else {
						metaTitle =
							seoData?.[`${item.prefix}_${item.key}`] !== ''
								? seoData?.[`${item.prefix}_${item.key}`]
								: seoData?.[`${item.key}`]
					}

					return {
						key: `${item.prefix}:${item.key}`,
						name: `${item.prefix}:${item.key}`,
						content: metaTitle
					}
				}

				return {
					key: `${item.prefix}:${item.key}`,
					name: `${item.prefix}:${item.key}`,
					content:
						seoData?.[`${item.prefix}_${item.key}`] !== ''
							? seoData?.[`${item.prefix}_${item.key}`]
							: seoData?.[`${item.key}`]
				}
			}).filter((item) => item !== null),
		[seoData, title]
	)

	return (
		<Head>
			<title>{seoData?.title}</title>
			<meta name="description" content={seoData?.description} />

			{metaTags.map(({ key, name, content }: MetaTagProps) => (
				<meta key={key} name={name} content={content} />
			))}
			{children}
		</Head>
	)
}

export default PageHead
