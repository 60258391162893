import React from 'react'

import { StatusWrapper, StatusCircle, StatusText } from './styles'
import { StatusInterface } from './types'

const Status = ({ state, text = '[Unknown state]' }: StatusInterface) => (
	<StatusWrapper>
		<StatusCircle state={state} />
		<StatusText>{text}</StatusText>
	</StatusWrapper>
)

export default Status
