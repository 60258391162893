import React, { useMemo } from 'react'

import { Icon, Button } from '@damen/ui'

import Actions from '@components/UI/Actions'

import { useSession } from 'next-auth/react'
import { hasPermission } from '@utils/authorization'
import { CASETYPE_WARRANTY_OPTION_ID } from '@src/constants/Keys'
import { formatUrl } from '@utils/formatUrl'
import { isAsset } from './helpers'
import { CallToActionProps } from './types'
import { SecondaryCta, Wrapper } from './styles'

const CallToAction: React.FC<CallToActionProps> = ({
	blok,
	details,
	callToActionSettings,
	onSecondaryCtaClick,
	secondaryCtaTitle,
	onClick
}) => {
	const blokOptions = useMemo(() => blok?.options ?? [], [blok?.options])
	const { data: session } = useSession()
	const { permissions } = session ?? {}

	const hasWarranty = useMemo(() => {
		if (details && isAsset(details)) {
			return details?.hasWarranty === true
		}
		return false
	}, [details])

	const options = useMemo(() => {
		return blokOptions
			?.map((item) => {
				if (!hasPermission(item.authorization, permissions)) {
					return null
				}

				if (
					!hasWarranty &&
					item.id === CASETYPE_WARRANTY_OPTION_ID &&
					isAsset(details)
				) {
					return null
				}

				const link = formatUrl(item.url)

				return {
					text: item.title,
					href: link,
					id: item.id
				}
			})
			.filter((x) => x)
	}, [blokOptions, details, hasWarranty, permissions])

	// There have been children configured, but none of them are visible, hide the whole button
	if (blokOptions.length > 0 && options.length === 0) {
		return null
	}

	return (
		<Wrapper>
			<Actions
				alignment={callToActionSettings.alignment}
				verticalAlignment={callToActionSettings.verticalAlignment}
				colorType={callToActionSettings.colorType}
				text={blok.title}
				items={options}
				onClick={onClick}
			/>
			{secondaryCtaTitle && onSecondaryCtaClick && (
				<SecondaryCta>
					<Button.Default
						text={secondaryCtaTitle}
						onClick={onSecondaryCtaClick}
						colorType="blue"
						icon={Icon.LeftChevron}
					/>
				</SecondaryCta>
			)}
		</Wrapper>
	)
}

export default CallToAction
