import React, { useState } from 'react'
import { Icon, Button } from '@damen/ui'
import SkeletonLoader from '@components/UI/SkeletonLoader'
import { AssetDetailsProps } from './types'
import {
	DataListDescription,
	DataListTitle,
	Details,
	MobileToggle,
	Wrapper
} from './styles'

const AssetDetails: React.FC<AssetDetailsProps> = ({
	blok,
	details,
	loading
}) => {
	const [toggled, setToggled] = useState(false)
	const {
		warrantyTitle,
		yardNumber: yardNumberTitle,
		type: typeTitle,
		delivered,
		hasWarranty: hasWarrantyLabel,
		hasNoWarranty,
		hideInformation,
		showInformation
	} = blok

	const returnDataList = (label: string, value: string) => (
		<dl>
			<DataListTitle>{label}</DataListTitle>
			<DataListDescription>{value}</DataListDescription>
		</dl>
	)

	if (loading) {
		const dls = [1, 2, 3, 4]

		return (
			<Wrapper>
				<Details>
					{dls.map((item) => {
						return (
							<dl key={item}>
								<DataListTitle>
									<SkeletonLoader style={{ opacity: 0.15 }} />
								</DataListTitle>
								<DataListDescription>
									<SkeletonLoader style={{ opacity: 0.15 }} />
								</DataListDescription>
							</dl>
						)
					})}
				</Details>
			</Wrapper>
		)
	}

	if (details) {
		const { hasWarranty, vesselType, deliveryDateFormatted, yardNumber } =
			details
		const warranty = hasWarranty ? hasWarrantyLabel : hasNoWarranty

		return (
			<Wrapper>
				<MobileToggle toggled={toggled}>
					<Button.Default
						text={toggled ? hideInformation : showInformation}
						onClick={() => setToggled(!toggled)}
						colorType="blue"
						buttonType="text"
						isSmall
						icon={Icon.LeftChevron}
					/>
				</MobileToggle>
				<Details toggled={toggled}>
					{warrantyTitle &&
						warranty &&
						returnDataList(warrantyTitle, warranty)}
					{delivered &&
						deliveryDateFormatted &&
						returnDataList(delivered, deliveryDateFormatted)}
					{yardNumberTitle &&
						yardNumber &&
						returnDataList(yardNumberTitle, yardNumber)}
					{typeTitle &&
						vesselType?.id &&
						returnDataList(typeTitle, vesselType?.id)}
				</Details>
			</Wrapper>
		)
	}

	return null
}

export default AssetDetails
