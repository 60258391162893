import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import { TabControl } from '@damen/ui'
import { TabsProps } from '@components/Tabs/Default/types'
import { getStoryblokLinkHref } from '@utils/linkResolver'

const Tabs: React.FC<TabsProps> = ({ blok }) => {
	const { locale, asPath, push } = useRouter()
	// Default for procurement at the moment
	const defaultRoute = `${locale}/procurement`

	const tabsObject = useMemo(() => {
		const tabs = blok.blocks.map((tab) => {
			let tabOnClick = () => {
				push(defaultRoute)
			}

			if (tab && 'url' in tab.link) {
				tabOnClick = () => push(getStoryblokLinkHref(tab.link))
			}

			const tabObject = {
				id: tab._uid,
				title: tab.title,
				onClick: tabOnClick
			}

			return tabObject
		})

		return tabs
	}, [blok.blocks, defaultRoute, push])

	const defaultIndex = useMemo(() => {
		const currentPageUrl = `/${locale}${asPath}`
		const lookedUpIndex = blok.blocks.findIndex(
			(item) => getStoryblokLinkHref(item.link) === currentPageUrl
		)

		return lookedUpIndex < 0 ? 0 : lookedUpIndex
	}, [locale, asPath, blok.blocks])

	return (
		<TabControl.Default
			defaultIndex={defaultIndex}
			tabcontrol={{ tabs: tabsObject }}
			lineColor="blue"
		/>
	)
}

export default Tabs
