import React, { MouseEvent } from 'react'
import { Button, Icon } from '@damen/ui'
import { Props as ButtonProps } from '@damen/ui/lib/cjs/components/Button/Default'

import {
	Wrapper,
	ActionsList,
	Action,
	LinkItemWrapper,
	LinkAction,
	ButtonAction
} from './styles'

export interface LinkItemProps {
	text: string
}

export interface ActionProps extends LinkItemProps {
	id: string
	href?: string
	onClick?: (() => void) | ((event: MouseEvent) => void)
}

export interface ActionsProps extends Omit<ButtonProps, 'onClick'> {
	items: ActionProps[]
	alignment?: 'left'
	verticalAlignment?: 'bottom'
	onClick?: () => void
}

export const LinkItem: React.FC<LinkItemProps> = ({ text }) => (
	<LinkItemWrapper>
		<Icon.LeftChevron width={5} height={8} />
		{text}
	</LinkItemWrapper>
)

const Actions: React.FC<ActionsProps> = ({
	items,
	alignment,
	verticalAlignment,
	onClick,
	icon = Icon.Plus,
	iconSize = 14,
	type = 'button',
	...rest
}) => {
	return (
		<Wrapper alignment={alignment} verticalAlignment={verticalAlignment}>
			<Button.Default
				type={type}
				icon={icon}
				iconSize={iconSize}
				onClick={onClick === undefined ? () => {} : onClick}
				// Focus styling showing the ActionsList does not work in Safari (MacOS & iOS) without this tabIndex attribute.
				// This is a bug in Safari, and the tabIndex attribute is a workaround.
				dataAttrs={{ tabIndex: '0' }}
				{...rest}
			/>
			{items?.length > 0 && (
				<ActionsList
					alignment={alignment}
					verticalAlignment={verticalAlignment}
				>
					{items.map((item) => (
						<Action key={item.id}>
							{item.href && (
								<LinkAction href={item.href} tabIndex={0}>
									<LinkItem text={item.text} />
								</LinkAction>
							)}

							{item.onClick && (
								<ButtonAction
									onClick={item.onClick}
									tabIndex={0}
								>
									<LinkItem text={item.text} />
								</ButtonAction>
							)}
						</Action>
					))}
				</ActionsList>
			)}
		</Wrapper>
	)
}

export default Actions
