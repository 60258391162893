import { Asset } from '@graphql/graphql'
import { NextRouter } from 'next/router'

export const goToAssetDetail =
	(router: NextRouter, item: Pick<Asset, 'id'>) => () => {
		router.push({
			pathname: '/my-fleet/[id]',
			query: { id: item.id }
		})
	}
