import React, { useCallback, useMemo } from 'react'
import {
	GetFavouriteAssetsDocument,
	useGetFavouriteAssetsQuery,
	useSetFavouriteAssetMutation
} from '@graphql/graphql'
import { Icon, theme } from '@damen/ui'

import { useTranslationContext } from '@contexts/TranslationContext'

import SkeletonLoader from '@components/UI/SkeletonLoader'

import useError from '@hooks/useError'
import { AssetFavouriteProps } from './types'
import { FavouriteButton } from './styles'

export const AssetFavouriteToggle = ({
	assetId,
	children,
	iconWidth,
	iconHeight
}: AssetFavouriteProps) => {
	// Hooks
	const { showError } = useError()

	// Context
	const { data, loading, error } = useGetFavouriteAssetsQuery()
	const isFavourite = useMemo(
		() => data?.favouriteAssets.some((asset) => asset.id === assetId),
		[data, assetId]
	)
	const { translations } = useTranslationContext()

	// API call
	const [setFavouriteAssetMutation, favoritesRequest] =
		useSetFavouriteAssetMutation({
			variables: {
				request: {
					isFavourite: !isFavourite,
					assetId
				}
			},
			refetchQueries: [{ query: GetFavouriteAssetsDocument }]
		})

	const isSending = favoritesRequest?.loading

	// Event handlers
	const clickHandler = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation()

			if (assetId && !isSending) {
				setFavouriteAssetMutation().catch(() => {
					showError(translations?.favouritesErrorMessage)
				})
			}
		},
		[
			assetId,
			isSending,
			setFavouriteAssetMutation,
			showError,
			translations?.favouritesErrorMessage
		]
	)

	if (loading) {
		return <SkeletonLoader width={17} height={17} />
	}

	if (error) {
		return null
	}

	return (
		<FavouriteButton onClick={clickHandler}>
			{!isSending && children ? (
				children
			) : (
				<Icon.Star
					width={iconWidth ?? 17}
					height={iconHeight ?? 17}
					fill={
						isFavourite
							? theme.colors.blue
							: theme.colors.greyAccentLight
					}
				/>
			)}
		</FavouriteButton>
	)
}
