import styled from 'styled-components'
import { theme } from '@damen/ui'

export const Wrapper = styled.div`
	position: relative;
	display: block;
	padding: 64px;
	background-color: ${theme.colors.blueIce};
`

export const Title = styled.h3`
	position: relative;
	display: block;
	color: ${theme.colors.marineBlack};
	font-size: ${theme.typography.fontSizeTextLarge}px;
	line-height: 1.6;
	margin-top: 32px;
	margin-bottom: 16px;
`

export const Content = styled.div`
	position: relative;
	display: block;
	color: ${theme.colors.marineBlack};
	font-size: ${theme.typography.fontSizeText}px;
	line-height: 1.88;
	margin-bottom: 16px;
`
