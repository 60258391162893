import React from 'react'
import { theme, Icon } from '@damen/ui'
import { useHeaderContext } from '@contexts/HeaderContext'
import Container from '@components/Container/Container'
import SkeletonLoader from '@components/UI/SkeletonLoader'
import {
	Wrapper,
	PreTitle,
	Title,
	FlexInner,
	Left,
	Right,
	Bottom,
	IconWrapper,
	BannerLeftChildrenWrapper,
	BottomIconWrapper
} from './styles'
import { BannerProps } from './types'

export const Banner = ({
	smallContainer,
	bannerLeft,
	bannerLeftChildren,
	bannerLeftWidth,
	bannerRight,
	bannerRightWidth,
	children,
	loading,
	bannerTitleAsync,
	showComponentOverlayIcon
}: BannerProps) => {
	const { headerColor } = useHeaderContext()

	return (
		<Wrapper color={headerColor}>
			<Container small={smallContainer}>
				<FlexInner>
					<Left
						bannerLeftWidth={bannerLeftWidth}
						fullWidth={!bannerRight}
					>
						{bannerLeft.icon && bannerLeft.icon === 'wave' && (
							<IconWrapper>
								<Icon.BowWaveSmall width={96} height={48} />
							</IconWrapper>
						)}
						{bannerLeft.icon && bannerLeft.icon === 'arrow' && (
							<IconWrapper>
								<Icon.Arrow
									width={63}
									height={40}
									fill={theme.colors.orange}
								/>
							</IconWrapper>
						)}
						{bannerLeft.preTitle && (
							<PreTitle>
								{loading ? (
									<SkeletonLoader width={250} />
								) : (
									bannerLeft.preTitle
								)}
							</PreTitle>
						)}
						<Title>
							{loading && bannerTitleAsync ? (
								<SkeletonLoader
									style={{
										opacity:
											headerColor === 'blue' ? 0.15 : 1
									}}
								/>
							) : (
								bannerLeft.title
							)}
						</Title>
						{bannerLeftChildren && (
							<BannerLeftChildrenWrapper>
								{bannerLeftChildren}
							</BannerLeftChildrenWrapper>
						)}
					</Left>
					{bannerRight && (
						<Right bannerRightWidth={bannerRightWidth}>
							{bannerRight}
						</Right>
					)}
				</FlexInner>

				{children && <Bottom>{children}</Bottom>}

				{showComponentOverlayIcon && (
					<BottomIconWrapper>
						<Icon.BowWave width={173} height={128} />
					</BottomIconWrapper>
				)}
			</Container>
		</Wrapper>
	)
}
export default Banner
