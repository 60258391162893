import React, { useCallback, useState } from 'react'
import SkeletonLoader from '@components/UI/SkeletonLoader'

import { useGetServiceRequestStatusItemsQuery } from '@graphql/graphql'
import { useLocale } from '@hooks/useLocale'
import { getStartsFromLocale } from '@utils/getStartsFromLocale'

import { theme, Icon } from '@damen/ui'

import Sidebar from '@components/Sidebar'
import Status from '@components/UI/Status'
import StatusExplanation from '@components/UI/StatusExplanation'
import ErrorState from '@components/UI/Error'
import caseStatusColorMapping from '@utils/colors/caseStatusColorMapping'

import { Content } from '@components/UI/StatusExplanation/styles'
import { Wrapper, Item, DataList } from './styles'
import { PartRequestPriorityStatusProps } from './types'

const PartRequestPriorityStatus = ({
	blok,
	details,
	loading
}: PartRequestPriorityStatusProps) => {
	const { locale } = useLocale()
	const {
		status: statusStoryblok,
		priority,
		titleStatusExplanation,
		errorDescription
	} = blok

	const [showPanel, setShowPanel] = useState(false)

	const {
		data,
		loading: caseStatusesLoading,
		error
	} = useGetServiceRequestStatusItemsQuery({
		variables: {
			language: locale,
			starts_with: getStartsFromLocale(locale)
		}
	})

	const iconInfoClickHandler = useCallback(() => {
		setShowPanel(true)
	}, [setShowPanel])

	const closePanelClickhandler = useCallback(() => {
		setShowPanel(false)
	}, [setShowPanel])

	if (loading || caseStatusesLoading) {
		return (
			<Wrapper>
				<Item hasImpact>
					<DataList>
						<dt>
							<SkeletonLoader width={50} />
						</dt>
						<dd>
							<SkeletonLoader width={50} />
						</dd>
					</DataList>
				</Item>
				<Item hasImpact>
					<DataList>
						<dt>
							<SkeletonLoader width={50} />
						</dt>
						<dd>
							<SkeletonLoader width={50} />
						</dd>
					</DataList>
				</Item>
			</Wrapper>
		)
	}

	if (details) {
		const { status, severity } = details
		const caseStatusInfo = data?.ServicerequeststatusItems?.items
		return (
			<>
				<Wrapper>
					{!!severity && (
						<Item hasImpact={!!severity}>
							<DataList>
								<dt>{priority}</dt>
								<dd>{severity?.name}</dd>
							</DataList>
						</Item>
					)}
					<Item hasImpact={!!severity}>
						<DataList>
							<dt>{statusStoryblok}</dt>
							<dd>
								<Status
									state={caseStatusColorMapping[status.id]}
									text={status.name}
								/>
								{caseStatusInfo.length > 0 && (
									<Icon.Info
										onClick={iconInfoClickHandler}
										fill={theme.colors.grey}
										height="16"
										width="16"
									/>
								)}
							</dd>
						</DataList>
					</Item>
				</Wrapper>
				{caseStatusInfo.length > 0 && (
					<Sidebar
						isOpen={showPanel}
						onClose={closePanelClickhandler}
					>
						{error ? (
							<Content>
								<ErrorState
									content={
										errorDescription ??
										'Something went wrong while retrieving case status information'
									}
									showBowWave
								/>
							</Content>
						) : (
							<StatusExplanation
								items={caseStatusInfo}
								title={
									titleStatusExplanation ??
									'[Status Explaination]'
								}
							/>
						)}
					</Sidebar>
				)}
			</>
		)
	}

	return null
}

export default PartRequestPriorityStatus
