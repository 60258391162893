import styled, { css } from 'styled-components'
import { theme } from '@damen/ui'
import { ImpactProps } from './types'

export const Wrapper = styled.div`
	display: flex;
`

export const Item = styled.div<ImpactProps>`
	display: flex;
	align-items: center;
	&:first-child {
		padding-right: 40px;
	}

	&:last-child {
		padding-left: 0px;
		border-left: none;
	}

	${({ hasImpact }) =>
		hasImpact &&
		css`
			&:last-child {
				padding-left: 40px;
				border-left: 1px solid ${theme.colors.greyAccentLight};
			}
		`}
`

export const DataList = styled.dl`
	dt,
	dd {
		font-size: ${theme.typography.fontSizeText}px;
	}

	dt {
		display: inline-block;
		color: ${theme.colors.grey};
		margin-right: 16px;
	}

	dd {
		display: inline-flex;
		align-items: center;
		gap: ${theme.layout.spacingSM};
		color: ${theme.colors.marineBlack};
	}
`
