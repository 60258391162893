import styled from 'styled-components'
import { CardElement } from '@components/UI/Card/styles'
import { theme } from '@damen/ui'
import { hexToRgb } from '@utils/helper'
import { SlideWrapper } from '@components/UI/Slider/styles'

export const FavouriteWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: visible;
	min-height: 467px;

	.slick-track {
		display: flex !important;
	}

	.slick-slide {
		height: inherit !important;

		> div {
			position: relative;
			display: block;
			height: 100%;
		}
	}

	${SlideWrapper} {
		height: 100%;
	}
`

export const Title = styled.h2`
	margin-bottom: 32px;
	font-size: ${theme.typography.fontSizeHeadingThree}px;

	@media ${theme.legacyMediaQueries.lg} {
		margin-bottom: 48px;
	}
`

export const IconWrapper = styled.div<{ hasImage: boolean }>`
	display: ${({ hasImage }) => (hasImage ? 'none' : 'block')};
`

export const StyledCard = styled(CardElement)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 24px;
	border-radius: 5px;
	border: none;
	background-color: ${hexToRgb(theme.colors.blueIce, {
		alpha: 0.05,
		format: 'css'
	})};
	transition: all 250ms ease-in-out;
	margin-bottom: 0;
	text-align: center;

	&:hover,
	&:focus {
		cursor: pointer;
		background-color: ${theme.colors.marineBlack};
		box-shadow: 10px 20px 40px 0
			${hexToRgb(theme.colors.marineBlack, {
				alpha: 0.3,
				format: 'css'
			})};
	}

	@media (max-width: 721px) {
		padding: 24px 40px 32px 40px;
	}

	img {
		position: relative;
		z-index: 1;
		object-fit: contain;
		min-width: 218px;
	}
`

export const InfoWrapper = styled(CardElement)`
	display: flex;
	flex-direction: column;
	max-width: 416px;
	padding: 40px 60px 40px 40px;
	margin-bottom: 48px;
	border-radius: 5px;
	border: none;
	background-color: ${hexToRgb(theme.colors.blueIce, {
		alpha: 0.05,
		format: 'css'
	})};
`

export const InfoTitle = styled.h3`
	margin-bottom: 16px;
`

export const InfoDescription = styled.span`
	display: flex;
	align-items: center;

	svg {
		margin-left: 8px;
	}
`

export const DisableWrapper = styled.div`
	display: flex;
	align-items: center;
	max-width: 416px;
`

export const CardTitle = styled.h3`
	margin-top: 56px;
	font-size: ${theme.typography.fontSizeHeadingFour}px;
`

export const CardHeader = styled.div`
	display: flex;
	justify-content: flex-end;
	align-self: flex-end;

	button {
		position: absolute;
		top: 24px;
		right: 24px;
	}
`
