import React from 'react'

import Status from '@components/UI/Status'

import { Content, StatusItem, StatusItemTitle, Title, Wrapper } from './styles'
import { StatusExplanationProps } from './types'

const StatusExplanation = ({ items, title }: StatusExplanationProps) => {
	return (
		<Wrapper>
			{title && <Title>{title}</Title>}
			<Content>
				{items.map((item) => {
					const {
						uuid,
						content: { name, description, colorReference }
					} = item
					return (
						<StatusItem key={uuid}>
							<StatusItemTitle>
								<Status text={name} state={colorReference} />
							</StatusItemTitle>
							<p>{description}</p>
						</StatusItem>
					)
				})}
			</Content>
		</Wrapper>
	)
}

export default StatusExplanation
