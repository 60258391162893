import { StepState } from '@damen/ui/lib/cjs/components/StepIndicator/types'

export const returnStoryblokComponent = (data: any) => {
	return (
		data?.map((item: any) => {
			if (item.component === 'globalReference') {
				const referenceObject = item.Reference.content.global[0]
				return referenceObject
			}
			return item
		}) ?? []
	)
}

export function getRequestStepState(currentStep, index): StepState {
	let stepState = StepState.inactive
	if (currentStep === index) {
		stepState = StepState.active
	}
	if (currentStep > index) {
		stepState = StepState.completed
	}
	return stepState
}
