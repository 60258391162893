import React from 'react'
import { Icon } from '@damen/ui'
import { ResponseMessageProps } from './types'
import { Wrapper, Title, Content } from './styles'

const ResponseMessage: React.FC<ResponseMessageProps> = ({
	title,
	content,
	children
}) => (
	<Wrapper>
		<Icon.BowWave width={86} height={64} fill="#2546b1" />
		{title && <Title>{title}</Title>}
		{content && <Content>{content}</Content>}
		{children}
	</Wrapper>
)

export default ResponseMessage
