import { theme } from '@damen/ui'

// The following types exist in the CaseStatuses type,
// but are not part of the status mapping from Damen and as such are not mapped:
// - SolutionExecution -> Should not be used as a status
// - Unknown

const caseStatusColorMapping = {
	// Grey
	Draft: theme.colors.silverGrey500,
	New: theme.colors.silverGrey500,
	Submitted: theme.colors.silverGrey500,
	// Yellow
	InProgress: theme.colors.yellow,
	PartsOrderedOrDeliveryInProgress: theme.colors.yellow,
	WorkScheduledOrInProgress: theme.colors.yellow,
	// Red
	YourResponseNeeded: theme.colors.red,
	OnHold: theme.colors.red,
	VesselUnavailable: theme.colors.red,
	DockingIssue: theme.colors.red,
	// Green
	Resolved: theme.colors.green,
	Closed: theme.colors.green
}

export default caseStatusColorMapping
