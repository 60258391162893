import styled from 'styled-components'
import { theme } from '@damen/ui'
import caseStatusColorMapping from '@utils/colors/caseStatusColorMapping'
import { StatusCircleInterface } from './types'

// TODO: Technical debt - Status circle assumes the prop.state is a hex value
// but in other places - the value is the caseStatus (eg. InProgress)
// We should avoid using caseStatusColorMapping and instead have the backend
// determine the colour to display.
export const StatusCircle = styled.span<StatusCircleInterface>`
	width: 8px;
	height: 8px;
	min-width: 8px;
	min-height: 8px;
	margin: 8px 12px 0 0;
	border-radius: 100%;
	background-color: ${(props) =>
		caseStatusColorMapping[props.state] ??
		props.state ??
		theme.colors.silverGrey500};
	display: inline-block;
`
export const StatusWrapper = styled.div`
	display: flex;
`
export const StatusText = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`
