import React, { useCallback, useState } from 'react'
import { theme } from '@damen/ui'

import { Button, ButtonText, ButtonOptional, Wrapper } from './styles'
import { TabItemProps, TabsProps } from './types'

const Tabs: React.FC<TabsProps> = ({ defaultValue, items = [], onSelect }) => {
	const [selected, setSelected] = useState(defaultValue)

	const buttonClickHandler = useCallback(
		(id) => {
			setSelected(id)

			if (onSelect) {
				onSelect(id)
			}
		},
		[onSelect]
	)

	return (
		<Wrapper>
			{items?.map((item: TabItemProps) => (
				<Button
					key={item.id}
					onClick={() => buttonClickHandler(item.id)}
					isSelected={selected === item.id}
				>
					{item.Icon && (
						<item.Icon
							width={24}
							height={24}
							fill={theme.colors.marineBlack}
						/>
					)}
					<ButtonText>{item.label}</ButtonText>
					{item.optional && (
						<ButtonOptional>{item.optional}</ButtonOptional>
					)}
				</Button>
			))}
		</Wrapper>
	)
}

export default Tabs
