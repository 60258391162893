import styled from 'styled-components'

import { theme } from '@damen/ui'

import { TabButtonProps } from './types'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: horizontal;
`

export const Button = styled.button<TabButtonProps>`
	display: inline-flex;
	padding: 12px 24px;
	align-items: center;
	height: 48px;
	border: 0;
	outline: none;
	cursor: ${(props) => (props.isSelected ? 'default' : 'pointer')};
	border-radius: 24px;
	background-color: ${(props) =>
		props.isSelected ? theme.colors.blueIce : 'transparent'};
	transition: background-color ${theme.timing.fast} ease-in-out;
	margin: 0 4px;
	flex-grow: 0;
	flex-shrink: 0;

	&:first-of-type {
		margin-left: 0;
	}

	&:last-of-type {
		margin-right: 0;
	}

	svg {
		flex-grow: 0;
		flex-shrink: 0;
	}

	&:hover,
	focus {
		background-color: ${theme.colors.blueIce};
	}
`

export const ButtonText = styled.span`
	font-size: 16px;
	font-weight: 500;
	color: ${theme.colors.marineBlack};
	line-heibght: 1;
	flex-grow: 0;
	flex-shrink: 0;
	margin: auto 8px;
`

export const ButtonOptional = styled.span`
	font-size: 16px;
	color: ${theme.colors.grey};
	flex-grow: 0;
	flex-shrink: 0;
	margin: auto auto auto 0;
`
