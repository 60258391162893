import React, { useCallback } from 'react'

import { Banner as BannerUiComponent } from '@components/UI/Banner'
import CallToAction from '@components/CallToAction'
import ServiceRequestPriorityStatus from '@components/ServiceRequestPriorityStatus'
import PartRequestPriorityStatus from '@components/PartRequestPriorityStatus'
import AssetDetails from '@components/AssetDetails'
import AssetOverviewFavourites from '@components/AssetOverviewFavourites'
import LinkTiles from '@components/UI/LinkTiles'
import Tabs from '@components/Tabs'

import { useHeaderContext } from '@contexts/HeaderContext'
import { BannerProps } from './types'
import { returnStoryblokComponent } from './helper'

const callToActionRightSettings = {
	alignment: 'left',
	colorType: 'white'
}
const callToActionLeftSettings = {
	alignment: 'right',
	colorType: 'white'
}
const Components = {
	callToAction: CallToAction
}
const leftComponents = {
	assetDetails: AssetDetails,
	callToAction: CallToAction
}
const bottomComponents = {
	priorityAndStatus: ServiceRequestPriorityStatus,
	partsPriorityAndStatus: PartRequestPriorityStatus,
	linkTiles: LinkTiles,
	assetOverviewFavourites: AssetOverviewFavourites,
	tabs: Tabs.Default
}

const Banner = ({
	blok: blokData,
	details,
	loading,
	bannerTitleAsync,
	dynamicTitle,
	bannerLeftWidth,
	bannerRight,
	bannerRightWidth = '50%',
	currentPageUrl,
	subtitle,
	onClick
}: BannerProps) => {
	const {
		left,
		right,
		blocks,
		title,
		bannerIcon,
		smallContainer,
		secondaryCtaTitle,
		showComponentOverlayIcon
	} = blokData

	const { setSupportOpened } = useHeaderContext()

	const BannerRightContent = returnStoryblokComponent(right)
	const BannerBottomContent = returnStoryblokComponent(blocks)
	const BannerLeftContent = returnStoryblokComponent(left)

	const secondaryCtaClickHandler = useCallback(() => {
		setSupportOpened(true)
	}, [setSupportOpened])

	const RenderContent = (blok: any) => {
		if (typeof Components[blok.component] !== 'undefined') {
			return React.createElement(Components[blok.component], {
				key: blok._uid,
				blok,
				callToActionSettings: callToActionRightSettings,
				onClick,
				details
			})
		}

		return undefined
	}

	const RenderLeftContent = (blok: any) => {
		if (typeof leftComponents[blok.component] !== 'undefined') {
			return React.createElement(leftComponents[blok.component], {
				key: blok._uid,
				blok,
				callToActionSettings: callToActionLeftSettings,
				secondaryCtaTitle,
				onSecondaryCtaClick: secondaryCtaClickHandler,
				details,
				loading
			})
		}

		return undefined
	}

	const RenderBottomContent = (blok: any) => {
		if (typeof bottomComponents[blok.component] !== 'undefined') {
			return React.createElement(bottomComponents[blok.component], {
				key: blok._uid,
				blok,
				details,
				loading,
				currentPageUrl
			})
		}

		return undefined
	}

	return (
		<BannerUiComponent
			bannerLeft={{
				title: bannerTitleAsync && dynamicTitle ? dynamicTitle : title,
				icon: bannerIcon,
				preTitle: subtitle
			}}
			bannerLeftWidth={bannerLeftWidth}
			bannerLeftChildren={
				BannerLeftContent
					? BannerLeftContent.map((item) => (
							<React.Fragment key={item._uid}>
								{RenderLeftContent(item)}
							</React.Fragment>
					  ))
					: null
			}
			/* eslint react/no-children-prop: 0 */
			bannerRight={
				BannerRightContent.length > 0
					? BannerRightContent.map((item) => (
							<React.Fragment key={item._uid}>
								{RenderContent(item)}
							</React.Fragment>
					  ))
					: bannerRight
			}
			/* eslint react/no-children-prop: 1 */
			smallContainer={smallContainer}
			bannerRightWidth={bannerRightWidth}
			children={
				BannerBottomContent
					? BannerBottomContent.map((item) => (
							<React.Fragment key={item._uid}>
								{RenderBottomContent(item)}
							</React.Fragment>
					  ))
					: null
			}
			loading={loading}
			bannerTitleAsync={bannerTitleAsync}
			showComponentOverlayIcon={showComponentOverlayIcon}
		/>
	)
}

export default Banner
