import styled from 'styled-components'

export const FavouriteButton = styled.button`
	background: transparent;
	border: 0;
	cursor: pointer;

	&:focus {
		outline: none;
	}
`
